import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import 'dayjs/locale/th';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(buddhistEra);
dayjs.tz.setDefault('Asia/Bangkok');
dayjs.locale('th');

interface DateRenderProps {
  type?: 'short' | 'long';
  startDate: Dayjs | string | Date | number;
  endDate?: Dayjs | string | Date | number;
  dateSeparator?: string;
  localTh?: boolean;
  showTime?: boolean;
}

const RangeRender = ({
  type,
  startDate: dat,
  endDate: dat2,
  dateSeparator = ' - ',
  localTh = true,
  showTime = false,
}: DateRenderProps) => {
  let years = 'YYYY';
  if (localTh) {
    years = 'BBBB';
  }
  const checkTypeStartDate =
    typeof dat === 'number' ? dayjs.unix(dat) : dayjs(dat);
  const checkTypeEndDate2 =
    typeof dat2 === 'number' ? dayjs.unix(dat2) : dayjs(dat2);
  if (!checkTypeStartDate.isValid()) return null;
  if (!checkTypeEndDate2.isValid()) return null;

  const renderDate = (type: 'short' | 'long' | undefined) => {
    switch (type) {
      case 'short':
        return `${checkTypeStartDate.format('DD')}${showTime ? ' - HH:mm' : ''}${dateSeparator}${checkTypeEndDate2.format('DD MMM ' + years + (showTime ? ' - HH:mm' : ''))}`;
      case 'long':
        return `${checkTypeStartDate.format('DD MMMM ' + years + (showTime ? ' - HH:mm' : ''))}${dateSeparator}${checkTypeEndDate2.format('DD MMMM ' + years + (showTime ? ' - HH:mm' : ''))}}`;
      default:
        return `${checkTypeStartDate.format('DD MMMM' + (showTime ? ' - HH:mm' : ''))}${dateSeparator}${checkTypeEndDate2.format('DD MMMM ' + years + (showTime ? ' - HH:mm' : ''))}`;
    }
  };

  return renderDate(type);
};

export default RangeRender;
